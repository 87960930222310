.Portfolio-List {
    position: relative;
    top: 15vmin;
    list-style: none;
    margin-top: 8vh;
}

.Portfolio-List li {
    display: block;
    border: none;
    color: #686c74;
}