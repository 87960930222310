.Show-Hide-Button {
    position: relative;
    top: 35vmin;
    padding: .5vw 41vw;
    font-size: calc(5px + 2vmin);
    color: #686c74;
    border: .5vmin solid #686c74;
    /*border-radius: 1vmin;*/
    background-color: #282c34;
}

.Show-Hide-Button:hover {
    color: #282c34;
    background-color: #686c74;
    font-weight: bold;
}

.Resume-Iframe {
    position: relative;
    width: 85vw;
    height: 100vh;
    top: 35vmin;
    margin-bottom: 36vmin;
}

.Resume-Download-Link {
    position: relative;
    top: 30vmin;
}