.Programming-Div {
    position: relative;
    margin: auto;
    margin-top: 8vh;
    width: 75vmax;
    color: #686c74;
    top: 15vmin;
}

.Programming-List {
    position: relative;
    top: 20vmin;
    list-style: none;
    margin-top: 8vh;
}

.Programming-List li {
    display: block;
    border: none;
    color: #686c74;
}

h3 {
    color: #586480;
    margin-bottom: 1vmin;
}

.Show-Hide-Content {
    position: relative;
    padding: .5vw .5vw;
    font-size: calc(5px + 2vmin);
    color: #686c74;
    border: .5vmin solid #686c74;
    text-decoration: underline;
}

.Show-Hide-Content:hover {
    color: white;
}

.Code-Block {
    position: relative;
    width: 75vw;
    margin: auto;
    margin-bottom: 5vmin;
    text-align: left;
    font-family: "Fira Code", monospace;
    font-size: calc(3px + 1.5vmin);
}