.App-Div {
  text-align: center;
  /*background-color: #282c34;*/
}

.App-Header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

h1 {
  margin: 0;
  margin-top: 5vh;
}

h2 {
  margin: 2vh;
}

a {
  color: #686c74;
}
a:hover {
  color: #fff;
}

ul {
  padding: 0 2vw;
  /*color: white;*/
}

li {
  position: relative;
  display: inline;
  z-index: 2;
  border-right: .25vw solid #121218;
}

li:last-child {
  border-right: none;
}

.Home-Header {
  position: absolute;
  top: 8vmax;
  left: 2vmin;
  right: 2vmin;
}

.Title-Header {
  position: absolute;
  top: -15vmin;
  left: 2vmin;
  right: 2vmin;
  /*background-color: rgba(0,0,0,1);*/
}

.Content-Header {
  position: absolute;
  top: 1vmin;
  left: 2vmin;
  right: 2vmin;
  z-index:1;
  color: #888cb4;
  /*color: #687c74;*/
  /*background-color: rgba(0,0,0,1);*/
}

.Content-Div {
  background-color: #282c34;
  /*background-color: #ff0;*/
  position: absolute;
  margin: 1vw 0;
  padding: 0 0 10vh 0;
  /*min-height: .75vh;*/
  width: 100%;
  top: 30vmin;
}

.Nav-List-Item {
  position: sticky;
  /*top: 15vmin;*/
}

.Nav-List-Item-Selected {
  padding: 0 2.5vw;
  height: 50vh;
  color: #484c54;
  /*top: 15vmin;*/
}
.Nav-Div {
  position: absolute;
  top: 5vmin;
}
.Home-Nav-List-Item {
  top: 20vh;
}

.Link-Tag {
  margin: .25vw;
  padding: 1.5vw 2.25vw;
  text-decoration: none;
  color: #686c74;
}

.Home-List {
  padding: 1vw 0 10vmin 0;
  margin: 0 0 5vw 0;
  /*width: 100%;*/
  /*height: 100%;*/
  /*background-color: #aaa;*/
}

/* narrow screens (like a phone) */
@media (max-width: 500px){
  li{
    border-right: .75vw solid #121218;
  }
  .Nav-List-Item-Selected {
    padding: 0 1vw;
  }
  .Link-Tag {
    padding: 1vw .5vw;
  }
}

@media (max-width: 300px){
  li {
    border-right: .5vw solid #121218;
  }
  .Link-Tag {
    padding: 0 .25vw;
  }
}